/* Detailed Itinerary Styles */
.detailed-itinerary .MuiAppBar-colorDefault {
    background-color: #494949;
}

.detailed-itinerary .MuiTab-textColorPrimary,
.detailed-itinerary .MuiTab-textColorPrimary.Mui-selected {
    color: #ffffff !important;
}

.detailed-itinerary .PrivateTabIndicator-colorPrimary-234 {
    background-color: #dbaa79 !important;
}

.detailed-itinerary .PrivateTabIndicator-root-233 {
    bottom: 15px;
}

.detailed-itinerary .MuiTabs-flexContainer {
    max-width: 960px;
    margin: 0 auto;
    padding: 10px 0;
}

.detailed-itinerary .daily-breakdown .MuiPaper-elevation1 {
    box-shadow: none;
}

.detailed-itinerary .daily-breakdown .MuiExpansionPanelSummary-root {
    padding: 0;
}

.detailed-itinerary .daily-breakdown .MuiExpansionPanel-root:before {
    display: none;
}

.detailed-itinerary .daily-breakdown .MuiIconButton-edgeEnd {
    margin-right: 180px;
    padding: 0;
}

.detailed-itinerary .daily-breakdown .slick-slider {
    max-width: 420px;
    margin: 0 auto;
    position: sticky;
    top: 115px;
}

.detailed-itinerary .daily-breakdown .daily-breakdown-included ul {
    margin: 0;
    padding: 0;
}

.detailed-itinerary .daily-breakdown .daily-breakdown-included li {
    text-align: left;
    list-style-position: inside;
    font-size: 16px;
}

.detailed-itinerary .daily-breakdown .slick-dots {
    bottom: 20px !important;
}

.detailed-itinerary .daily-breakdown .daily-breakdown-grid:nth-of-type(2n) {
    flex-direction: row-reverse;
}

.detailed-itinerary
    .daily-breakdown
    .daily-breakdown-grid:nth-of-type(2n)
    .daily-breakdown-grid-item {
    text-align: right;
}

.detailed-itinerary .daily-breakdown .daily-breakdown-grid-item {
    text-align: left;
}

/* .detailed-itinerary
    .daily-breakdown
    .daily-breakdown-grid:nth-of-type(2n)
    .daily-activities-panel {
    float: right;
} */

.detailed-itinerary
    .daily-breakdown
    .daily-breakdown-grid:nth-of-type(2n)
    .MuiExpansionPanelSummary-content {
    display: block;
    text-align: right;
}

.detailed-itinerary
    .daily-breakdown
    .daily-breakdown-grid:nth-of-type(2n)
    .MuiIconButton-edgeEnd {
    margin-right: 0;
}

/* Detailed Itinerary > Experience Details Content */
/* .detailed-itinerary .experience-details .slick-dots li button,
.more-from-bandr .slick-dots li button {
    border: 1px solid #000 !important;
} */

.detailed-itinerary
    .experience-details
    .slick-dots
    li.slick-active
    button:before,
.more-from-bandr .slick-dots li.slick-active button:before {
    color: #000 !important;
}

.detailed-itinerary .experience-details .experience-details-inclusions-panel {
    box-shadow: none;
}

.detailed-itinerary
    .experience-details
    .experience-details-inclusions-panel
    .MuiExpansionPanelSummary-root {
    padding: 0;
}

.detailed-itinerary
    .experience-details
    .experience-details-inclusions-panel
    .MuiIconButton-edgeEnd {
    margin-right: 248px;
    padding: 5px;
}

.detailed-itinerary
    .experience-details
    .experience-details-inclusions-panel
    .MuiSvgIcon-root {
    width: 12px;
    height: 12px;
}

.detailed-itinerary .MuiBox-root {
    padding: 0;
}

.detailed-itinerary .MuiTab-wrapper {
    font-family: "Muli", "sans-serif";
}

.MuiBottomNavigationAction-root.Mui-selected {
    color: #194666 !important;
}

.MuiBottomNavigationAction-root {
    min-width: 50px !important;
}

.hotels-container .MuiGrid-container {
    display: flex !important;
}
