.embla {
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

.embla__viewport {
    overflow: hidden;
    width: 100%;
    padding: 0px 15px 10px 15px;
}

.embla__image__viewport {
    overflow: hidden;
    width: 100%;
    padding: 0;
}

.embla__viewport.is-draggable {
    cursor: move;
    cursor: grab;
}

.embla__viewport.is-dragging {
    cursor: grabbing;
}

.embla__container {
    display: flex;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: transparent;
    margin-left: -10px;
}

.embla__slide {
    position: relative;
    min-width: 33%;
    padding: 15px 0px 15px 15px;
}

@media (max-width: 600px) {
    .embla__slide {
        min-width: 100%;
    }
}

.embla__image__slide {
    position: relative;
    min-width: 100%;
    padding: 0;
}

.embla__slide__inner {
    position: relative;
    overflow: hidden;
    height: 190px;
}

.embla__slide__img {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    width: auto;
    min-height: 100%;
    min-width: 100%;
    max-width: none;
    transform: translate(-50%, -50%);
}

.embla__button {
    outline: 0;
    cursor: pointer;
    background-color: transparent;
    touch-action: manipulation;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    border: 0;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    fill: #1bcacd;
    padding: 0;
}

.embla__button:disabled {
    cursor: default;
    opacity: 0.3;
}

.embla__button__svg {
    width: 100%;
    height: 100%;
}

.embla__button--prev {
    left: 27px;
}

.embla__button--next {
    right: 27px;
}

.embla__dots {
    display: flex;
    list-style: none;
    justify-content: center;
    position: absolute;
    width: 100%;
    bottom: 10px;
}

.embla__dot {
    background-color: transparent;
    cursor: pointer;
    position: relative;
    padding: 0;
    outline: 0;
    border: 0;
    width: 12px;
    height: 25px;
    margin-right: 7.5px;
    margin-left: 7.5px;
    display: flex;
    align-items: center;
}

.embla__dot:after {
    background-color: #fafafa00;
    width: 100%;
    height: 12px;
    border-radius: 12px;
    content: "";
    border: #fafafa solid 1px;
}

.embla__dot.is-selected:after {
    background-color: #fafafa;
    opacity: 1;
}
