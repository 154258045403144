.MuiRadio-colorSecondary.Mui-checked {
    color: #494949;
}

.MuiFormLabel-root {
    font-size: 16px;
}

.MuiFormControlLabel-root span {
    font-size: 16px;
}