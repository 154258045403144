/*--------------------------------------------------------------
## Menus
--------------------------------------------------------------*/
.site-header {
    background: #194666;
    height: 95px;
    position: fixed;
    width: 100%;
    z-index: 99;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    transition: all 0.3s ease-in-out;
}

.site-header.small {
    height: 60px;
    transition: all 0.5s ease-in-out;
}

.site-branding {
    width: 15%;
    padding-top: 5px;
}

.main-navigation {
    clear: both;
    padding-top: 5px;
    display: block;
    width: 65%;
    font-weight: 500;
    font-style: normal;
    margin: 0 auto;
}

.main-navigation ul {
    display: none;
    list-style: none;
    margin: 0;
    padding-left: 0;
}

.main-navigation ul ul {
    /* box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); */
    float: left;
    position: absolute;
    top: 100%;
    left: -999em;
    z-index: 99999;
    width: 15rem;
    padding-top: 2rem;
    background: #194666;
}

.main-navigation li ul li {
    display: block;
    text-align: left;
    padding: 10px;
}

.main-navigation ul ul ul {
    left: -999em;
    top: 0;
}

.main-navigation ul ul li:hover > ul,
.main-navigation ul ul li.focus > ul {
    left: 100%;
}

.main-navigation ul ul a {
    width: 200px;
}

.main-navigation ul li:hover > ul,
.main-navigation ul li.focus > ul {
    left: auto;
}

.main-navigation li {
    position: relative;
    padding: 0px 20px;
    display: inline-block;
}

.main-navigation a {
    font-family: "Muli", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
}

.main-navigation a:hover {
    color: #33ccff;
    text-decoration: none;
}

.main-navigation .menu-main-menu-container {
    max-width: 800px;
    margin: auto;
    text-align: center;
}

.search-start-planning {
    width: 20%;
    display: flex;
    align-items: center;
    padding: 0 20px;
}

.header-search-field input {
    border: 0;
    color: #fff !important;
    font-family: "Lora", serif;
}

.header-search-field .MuiInput-underline:before {
    border-bottom: 1px solid #fff !important;
}

.login-navigation a {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    text-transform: capitalize;
    text-decoration: none;
    color: #fff;
}

.login-navigation a:hover {
    color: #33ccff;
    text-decoration: none;
}

/* Small menu. */
.menu-toggle,
.main-navigation.toggled ul {
    display: block;
}

@media screen and (min-width: 37.5em) {
    .menu-toggle {
        display: none;
    }
    .main-navigation ul {
        display: block;
    }
}

.site-main .comment-navigation,
.site-main .posts-navigation,
.site-main .post-navigation {
    margin: 0 0 1.5em;
    overflow: hidden;
}

.comment-navigation .nav-previous,
.posts-navigation .nav-previous,
.post-navigation .nav-previous {
    float: left;
    width: 50%;
}

.comment-navigation .nav-next,
.posts-navigation .nav-next,
.post-navigation .nav-next {
    float: right;
    text-align: right;
    width: 50%;
}

/*Mobile Menu Styles */
.mobile-menu-panel .MuiPaper-root.MuiDrawer-paper {
    background-color: #194666;
}

.mobile-menu-panel
    .mobile-menu-item:nth-of-type(1)
    .MuiCollapse-container.MuiCollapse-entered {
    width: 50%;
    float: right;
}
