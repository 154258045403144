/*Footer Custom Styles*/
.site-footer {
    height: 600px;
    background: black;
    margin-top: 32px;
}

.footer-wrapper {
    display: flex;
    justify-content: space-around;
    max-width: 1200px;
    margin-right: auto;
    padding: 20px;
}

.footer-logo {
    width: 20%;
}

.footer-logo-container {
    max-width: 160px;
    margin-right: auto;
}

.footer-navigation {
    width: 80%;
}

.footer-navigation-container {
    max-width: 830px;
    margin-right: auto;
    text-align: left;
    color: white;
}

.footer-menu-item ul {
    padding: 0;
}

.footer-menu-item ul li a:hover {
    color: #3cf;
}

.footer-navigation-container p {
    font-weight: 600;
    font-family: "Muli", sans-serif;
}

.footer-navigation-container ul {
    margin: 1rem 0;
}

.footer-navigation-container li {
    list-style: none;
}

.footer-navigation-container a {
    color: white;
    font-size: 14px;
    font-weight: 500;
    font-family: "Muli", sans-serif;
}

.contact-us-container {
    text-align: left;
    font-weight: 600;
    padding: 0 20px 20px 20px;
    color: #fff;
    font-weight: 500;
    font-size: 11px;
    display: flex;
}

.contact-us-container a {
    font-family: "Muli", sans-serif;
    font-weight: 500;
}

.contact-us-container p {
    color: #fff;
}

.contact-us-container span {
    color: #3cf;
}

.site-info {
    height: 75px;
    padding: 25px 0;
    background: white;
    text-align: center;
}

.terms-privacy a {
    color: #fff;
    text-decoration: none;
    text-align: left;
}
