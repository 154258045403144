@keyframes move-around {
    0% {
        transform: translate(-40%, -35%);
    }

    25% {
        transform: translate(40%, -35%);
    }

    50% {
        transform: translate(40%, 35%);
    }

    75% {
        transform: translate(-40%, 35%);
    }

    100% {
        transform: translate(-40%, -35%);
    }
}

@keyframes scale {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }
}

.logo {
    animation: scale 2s infinite;
}
